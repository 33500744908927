import React from 'react';
import styles from './Advantages.module.scss';
    
const Advantages = () => (
    <div className={styles.wrapper} id="cons">
        <div className={styles.container}>
            <h4 className={styles.title}>Переваги курсу</h4>
            <div className={styles.allTogether}>
                <div className={styles.item}>
                    <h5>Оптимальність</h5>
                    <p> Оптимальний баланс між онлайн-уроком наживо, і уроками в записі. Запис дозволяє працювати у власному темпі і в зручний для себе час. Онлайн-уроки наживо - це можливість отримати відповідь на своє запитання в реальному часі.</p>
                </div>
                <div className={styles.item}>
                    <h5>Мобільність</h5>
                    <p>Учні мають можливість навчатися у зручний час і в будь-якому місці де є вихід в інтернет так як записи уроків зберігаються. Переглядати відеоуроки, як з комп’ютера так і з будь-якого іншого ґаджета.</p>
                </div>
                <div className={styles.item}>
                    <h5>Доступність</h5>
                    <p>Інформація подається просто, чітко та зрозуміло для кожного учня. Вивчення основних означень та формул здійснюється не шляхом зубріння, а методом асоціацій та ситуаційних процесів.</p>
                </div>
                <div className={styles.item}>
                    <h5>Економія</h5>
                    <p>Онлайн-курси заощадять найцінніший ресурс – час та кошти, адже оплата курсу не потребує внесення повної вартості навчання одразу. Вам не доведеться витрачати додатково час на дорогу до репетитора або освітнього центру.</p>
                </div>
            </div>
        </div>
    </div>
);

export default Advantages;