import React from 'react';
import SimpleHeader from '../../components/SimpleHeader';
import styles from './Dogovir.module.scss';

const Dogovir = () => (
    <>
    <SimpleHeader />
    <div className={styles.mainContent}>
      <h2>
        ПУБЛІЧНИЙ ДОГОВІР <br />
        (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ) <br />
        про надання послуг у сфері підготовки до Національного мультипредметного тесту
      </h2>
      <div className={styles.p}>
        Цей Договір, в якому одна сторона Фізична особа – підприємець Мельниченко Олена Петрівна, зареєстрований в Єдиному державному реєстрі юридичних осіб та фізичних осіб – підприємців в установленому чинним законодавством порядку, та який є платником єдиного податку (надалі – Виконавець), з одного боку, і будь-яка особа, яка прийняла (акцептувала) дану пропозицію (надалі – Замовник), з іншого боку, далі разом — Сторони, а кожний окремо – Сторона, уклали даний Договір (далі — Договір), адресований необмеженому колу осіб, який є офіційною публічною пропозицією Виконавця, укласти з будь-яким Замовником Договір про надання послуг у сфері підготовки до Національного мультипредметного тесту (далі – НМТ). При замовленні та оплаті Послуг Виконавця, Замовники приймають умови цього Договору про нижченаведене.
      </div>
      <div className={styles.p}>
        <b>1. ЗАГАЛЬНІ ПОЛОЖЕННЯ</b><br />
        <b>1.1.</b> Цей Договір укладається шляхом надання повної й безумовної згоди (акцепту) Замовника на укладення Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.
      </div>
      <div className={styles.p}>
        <b>1.2.</b> Договір має юридичну силу відповідно до ст.ст. 633, 641, 642 Цивільного кодексу України і є рівносильним Договору, підписаному Сторонами.
      </div>
      <div className={styles.p}>
        <b>1.3.</b> Замовник підтверджує факт ознайомлення та згоди з усіма умовами цього Договору в повному обсязі шляхом акцептування.
      </div>
      <div className={styles.p}>
        <b>1.4.</b> Будь-яка з наступних дій вважається акцептом цього договору публічної оферти: – факт реєстрації Замовника на Веб-сайті Виконавця та оформлення Замовлення Послуг Виконавця на веб-сайті Виконавця https://mathwithme.in.ua/; – оплата Послуг Виконавця на умовах та в порядку, визначеними цим Договором та на відповідних сторінках Веб-сайту Виконавця https://mathwithme.in.ua/;
      </div>
      <div className={styles.p}>
        <b>1.5.</b> Укладаючи цей Договір Замовник автоматично погоджується з повним та безумовним прийняттям положень цього Договору і цін на Послуги.
      </div>
      <div className={styles.p}>
        <b>1.6.</b> Якщо Замовник не згодний з умовами Договору, він не має права укладати цей Договір, а також не вправі користуватися Послугами за цим Договором.
      </div>
      <div className={styles.p}>
        <b>2. ТЕРМІНИ І ВИЗНАЧЕННЯ</b><br />
        <b>«Договір публічної оферти»</b> - публічний договір, зразок якого розміщений на Веб-сайті https://mathwithme.in.ua/.
      </div>
      <div className={styles.p}>
        <b>«Акцепт»</b> - надання Замовником повної й безумовної згоди на укладення даного Договору в повному обсязі, без підпису письмового примірника Договору Сторонами.
      </div>
      <div className={styles.p}>
        <b>«Послуги»</b> – Послуги в сфері підготовки до НМТ (а саме – навчання Замовника чи особи в чиїх інтересах виступає Замовник на онлайн-курсах підготовки до НМТ), які надаються Виконавцем, та зазначені Виконавцем у відповідному розділі Веб-сайту Виконавця https://mathwithme.in.ua/.
      </div>
      <div className={styles.p}>
        <b>«Замовник»</b> - будь-яка дієздатна фізична, фізична особа-підприємець, що відвідала Веб-сайт https://mathwithme.in.ua/ та акцептувала даний Договір.
      </div>
      <div className={styles.p}>
        <b>«Виконавець»</b> - фізична особа підприємець, що надає послуги у сфері підготовки до НМТ та реквізити якого зазначено у розділі 12 даного Договору.
      </div>
      <div className={styles.p}>
        <b>«Замовлення»</b> - належно оформлена заявка Замовника на отримання Послуг, що адресується Виконавцю.
      </div>
      <div className={styles.p}>
        <b>3. ПРЕДМЕТ ДОГОВОРУ</b><br />
        <b>3.1.</b> Виконавець зобов’язується на умовах та в порядку, визначених цим Договором, надати Замовнику Послуги в сфері підготовки до НМТ (а саме – навчання Замовника чи особи в чиїх інтересах виступає Замовник на онлайн-курсах підготовки до НМТ), а Замовник зобов’язується на умовах та в порядку, визначених цим Договором, прийняти та оплатити замовлені Послуги.
      </div>
      <div className={styles.p}>
        <b>3.2</b> Замовник та Виконавець підтверджують, що даний Договір не є фіктивним чи удаваним правочином або правочином, укладеним під впливом тиску чи обману.
      </div>
      <div className={styles.p}>
        <b>3.3</b> Виконавець підтверджує, що має право на здійснення діяльності у сфері підготовки до НМТ, у відповідності з вимогами діючого законодавства України.
      </div>
      <div className={styles.p}>
        <b>4. ПРАВА ТА ОБОВ’ЯЗКИ ВИКОНАВЦЯ</b><br />
        <b>4.1.</b> Виконавець зобов’язаний:
        <li>виконувати умови даного Договору;</li>
        <li>надати Замовнику Послуги належної якості;</li>
        <li>
          об’єктивно інформувати Замовника про Послуги та умови їх надання на Веб-сайті https://mathwithme.in.ua/.
        </li>
        <div className={styles.p}>
          <b>4.2.</b> Виконавець має право:
          <li>в односторонньому порядку призупинити надання послуг за цим Договором у випадку порушення Замовником умов цього Договору;</li>
          <li>інші права відповідно до чинного законодавства України та цього Договору.</li>
        </div>
        <div className={styles.p}>
          <b>5. ПРАВА ТА ОБОВ’ЯЗКИ ЗАМОВНИКА</b><br />
          <b>5.1.</b> Замовник зобов’язаний:
          <li>своєчасно оплатити і отримати Замовлення на умовах цього Договору;</li>
          <li>ознайомитись з інформацією про Послуги, яка розміщена на сайті Виконавця.</li>
          <div className={styles.p}>
            <b>5.2.</b> Замовник має право:
            <li>оформити Замовлення Послуг, зазначених на відповідній сторінці Веб-сайту https://mathwithme.in.ua/;</li>
            <li>вимагати від Виконавця надання Послуг відповідно до умов цього Договору;</li>
            <li>інші права відповідно до чинного законодавства України та цього Договору.</li>
          </div>
        </div>
      </div>
      <div className={styles.p}>
        <b>6. ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</b><br />
        <b>6.1.</b> Замовник самостійно оформлює Замовлення на відповідній сторінці Веб-сайту https://mathwithme.in.ua/.
      </div>
      <div className={styles.p}>
        <b>6.2.</b> Строк обробки Виконавцем Замовлення – до 3-х (трьох) робочих днів з моменту його оформлення. У разі, якщо Замовлення відправлено у вихідний або святковий день, строк обробки Замовлення починається з першого після вихідного робочого дня.
      </div>
      <div className={styles.p}>
        <b>7. ЦІНА ПОСЛУГ І ПОРЯДОК ОПЛАТИ</b><br /> 
        <b>7.1.</b> Ціна Послуг визначається Виконавцем та зазначається на відповідній сторінці Веб-сайту https://mathwithme.in.ua/.<br />
        <b>7.1.1.</b> За згодою Виконавця допускається оплата частинами на визначений Виконавцем термін, у разі відмови Замовника внести частину плати у визначений термін, Виконавець має право припинити надання послуг визначених цим Договором, та частина коштів яка сплачена, не повертається.
      </div>
      <div className={styles.p}>
        <b>7.2.</b> Замовник оплачує Послуги Виконавця на підставі цього Договору у Національній валюті України – гривні.
      </div>
      <div className={styles.p}>
        <b>7.3.</b> Оплата Послуг здійснюється шляхом:
      </div>
      <div className={styles.p}>
        <b>7.3.1.</b> Перерахування грошових коштів на поточний рахунок Виконавця або
      </div>
      <div className={styles.p}>
        <b>7.3.2.</b> За допомогою інших платіжних засобів, які вказані на Веб-сайті Виконавця.
      </div>
      <div className={styles.p}>
        <b>7.4.</b> Моментом оплати Послуг вважається час зарахування грошових коштів на поточний рахунок Виконавця.
      </div>
      <div className={styles.p}>
        <b>7.5.</b> Замовник самостійно та за свій рахунок сплачує вартість послуг третіх осіб, якщо це необхідно для отримання Послуг Виконавця за цим Договором (наприклад, послуги доступу до мережі Інтернет та інші).
      </div>
      <div className={styles.p}>
        <b>
          8. ПОРЯДОК ОТРИМАННЯ ПОСЛУГ. ПОРЯДОК ПРИЙМАННЯ-ПЕРЕДАЧІ НАДАНИХ ПОСЛУГ.
        </b> <br />
        <b>8.1.</b> Правила надання та отримання Послуг зазначені на відповідній сторінці Веб-сайту https://mathwithme.in.ua/. Усі питання, що виникли в процесі оплати і отримання Послуг, Замовник може з'ясувати у Виконавця за контактними даними, зазначеними на сайті https://mathwithme.in.ua/ або в розділі 12 даного Договору.
      </div>
      <div className={styles.p}>
        <b>9. ВІДПОВІДАЛЬНІСТЬ СТОРІН ТА ВИРІШЕННЯ СПОРІВ</b><br />
        <b>9.1.</b> За невиконання або неналежне виконання своїх зобов’язань за цим Договором, сторони несуть відповідальність відповідно до чинного законодавства України.
      </div>
      <div className={styles.p}>
        <b>9.2.</b> Усі спори, що виникають з цього Договору або пов'язані із ним, вирішуються шляхом переговорів між Сторонами.
      </div>
      <div className={styles.p}>
        <b>9.3.</b> Якщо відповідний спір не можливо вирішити шляхом переговорів, він вирішується в судовому порядку за встановленою підвідомчістю та підсудністю такого спору відповідно до чинного в Україні законодавства.
      </div>
      <div className={styles.p}>
        <b>9.4.</b> Виконавець не несе будь-яку відповідальність за ненадання або неналежне надання Послуг Замовнику за умови настання будь-яких обставин, які виникли не з вини Виконавця (а саме – настання обставин, які виникли з вини чи необережності Замовника та / або настання обставин, які виникли з вини чи необережності будь-якої третьої особи (будь-яких третіх осіб) та /або настання форс-мажорних обставин).
      </div>
      <div className={styles.p}>
        <b>10. ФОРС-МАЖОРНІ ОБСТАВИНИ</b><br />
        <b>10.1.</b> Сторони звільняються від відповідальності за невиконання або неналежне виконання зобов'язань, що передбачені даним Договором, якщо воно виникло внаслідок форс-мажорних обставин.
      </div>
      <div className={styles.p}>
        <b>10.2.</b> Під форс-мажорними обставинами у даному Договорі розуміються будь-які обставини, що виникли поза волею або всупереч волі чи бажанню Сторін і яких не можна передбачити чи уникнути, включаючи: військові дії, громадські заворушення, епідемії, блокаду, землетруси, повені, пожежі, а також рішення чи приписи органів державної влади та управління держави, резидентом якої є Замовник, або держави, резидентом якої є Виконавець, внаслідок яких на Сторони (або одну із Сторін) покладатимуться додаткові обов'язки чи встановлюватимуться додаткові обмеження і які роблять неможливим подальше повне або часткове виконання Договору, а також інші дії чи події, що існують поза волею Сторін.
      </div>
      <div className={styles.p}>
       <b>10.3.</b> Якщо форс-мажорні обставини триватимуть більше 3-х (трьох) місяців поспіль, то кожна із Сторін матиме право відмовитись від подальшого виконання зобов'язань за цим Договором і, в такому разі, жодна із Сторін не матиме права на відшкодування іншою Стороною можливих збитків.
      </div>
      <div className={styles.p}>
        <b>11. ІНШІ УМОВИ ДОГОВОРУ</b><br />
        <b>11.1.</b> Кожна Сторона гарантує іншій Стороні, що володіє необхідною дієздатністю, а рівно всіма правами і повноваженнями, необхідними і достатніми для укладання і виконання цього Договору відповідно до його умов.
        </div>
      <div className={styles.p}>
        <b>11.2.</b> Одностороння зміна умов укладеного Договору Замовником чи відмова виконувати умови укладеного Договору Замовником є неприпустимою, за винятків випадків, передбачених цим Договором. Жодна зі Сторін цього Договору не має права передавати свої права та обов’язки третім особам без згоди на це другої Сторони.
      </div>
      <div className={styles.p}>
        <b>11.3.</b> Виконавець підтверджує, що є платником єдиного податку (група 3) за ставкою, передбаченою Податковим кодексом України.
      </div>
      <div className={styles.p}>
        <b>11.4.</b> Інформація, що надається Замовником є конфіденційною. Інформація про Замовника використовується виключно в цілях виконання його Замовлення.
      </div>
      <div className={styles.p}>
        <b>11.5.</b> Власним акцептуванням Договору Замовник добровільно надає згоду на збір та обробку власних персональних даних з наступною метою: дані, що стають відомі, використовуватимуться в комерційних цілях, в тому числі отримання інформації про замовлення та обробки інформації про нього, надсилання телекомунікаційними засобами зв’язку (електронною поштою, мобільним зв’язком) рекламних та спеціальних пропозицій, інформації про акції або будь-якої іншої інформації про діяльність Веб-сайту https://mathwithme.in.ua/. У випадку небажання отримувати інформацію про діяльність Веб-сайту https://mathwithme.in.ua/, Замовник має право звернутися до Виконавця і повідомити про відмову від отримання рекламних матеріалів.
      </div>
      <div className={styles.p}>
        <b>11.6.</b> Виконавець не несе відповідальність за зміст і правдивість інформації, що надається Замовником при оформленні Замовлення. Замовник несе відповідальність за достовірність вказаної при оформленні Замовлення інформації.
      </div>
      <div className={styles.p}>
        <b>11.7.</b> Замовнику надається право на використання Послуг Виконавця виключно у своїй внутрішній діяльності без права їх відчуження або передачі на користь третіх осіб.
      </div>
      <div className={styles.p}>
        <b>11.8.</b> Сторони зобов’язуються зберігати конфіденційну інформацію, отриману в результаті виконання цього Договору, за винятком випадків, коли це письмово санкціоновано іншою Стороною або вимагається державними органами відповідно до чинного законодавства. За розголошення конфіденційної інформації винна Сторона несе відповідальність згідно з чинним законодавством.
      </div>
      <div className={styles.p}>
        <b>11.9.</b> Договір є публічним і діє до його припинення у порядку, встановленому цим Договором. Сторони погодили, що дія цього Договору триває до дня НМТ з відповідного предмету поточного навчального року. Цей Договір вважається погодженим Замовником та укладеним за місцезнаходженням Виконавця з дати акцептування.
      </div>
      <div className={styles.p}>
        <b>11.10.</b> Виконавець самостійно у відповідності та на виконання вимог чинного законодавства України визначає умови даного Договору. Виконавець має право самостійно змінити та/або доповнити умови цього публічного Договору, включаючи правила надання та отримання Послуг за даним Договором. При цьому Виконавець гарантує та підтверджує, що розміщена на Веб-сайті Виконавця поточна редакція тексту цього Договору, включаючи правила надання та отримання Послуг за даним Договором, є дійсною.
      </div>
      <div className={styles.p}>
        <b>12. РЕКВІЗИТИ ВИКОНАВЦЯ</b>
      </div>
      <div className={styles.p}>Фізична особа-підприємець Мельниченко Олена Петрівна</div>
      <div className={styles.p}>Місцезнаходження: Київська обл. м. Біла Церква, вул. Зінченка 45</div>
      <div className={styles.p}>Код за ЄДРПОУ 2672810328</div>
      <div className={styles.p}>Банківські реквізити: UA023348510000000026008117814</div>
      <div className={styles.p}>АТ «ПУМБ»</div>
      <div className={styles.p}>E-mail: olena@mathwithme.in.ua </div>
    </div>
  </>
);

export default Dogovir;
