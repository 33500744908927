import React from 'react';
import { FaTasks, FaSquareRootAlt, FaCalendarCheck, FaPencilRuler } from "react-icons/fa";
import { GrSchedules, } from "react-icons/gr";
import { BsFillAwardFill } from 'react-icons/bs';
import styles from './Results.module.scss';

const Result = () => (
    <div className={styles.wrapper} id="results">
        <div className={styles.container}>
            <div className={styles.title}>
                Ваші результати навчання на нашому курсі
            </div>
            <div className={styles.results}>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <FaSquareRootAlt />
                    </div>
                    <div className={styles.mainText}>
                        Формули та означення
                    </div>
                    <div className={styles.text}>
                        Ви ознайомитеся з основними формулами, навчитеся їх розуміти та застосовувати. Вивчите основні означення методами асоціацій та розуміння. Побачите області їх застосування
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <FaCalendarCheck />
                    </div>
                    <div className={styles.mainText}>
                        Поняття
                    </div>
                    <div className={styles.text}>
                        Ви вивчите потрібні для НМТ математичні поняття без особливих зусиль та зубріння. Ви легко зможете застосовувати отримані знання при розв’язуванні задач
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <GrSchedules />
                    </div>
                    <div className={styles.mainText}>
                        Тести
                    </div>
                    <div className={styles.text}>
                        Ви навчитеся розв’язувати тестові завдання завдяки різним авторським прийомам. Я поділюся маленькими хитрощами та спрощеними алгоритмами розв'язання завдань формату НМТ
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <FaPencilRuler />
                    </div>
                    <div className={styles.mainText}>
                        Теорія
                    </div>
                    <div className={styles.text}>
                        Ви ознайомитеся з теоретичним матеріалом, що передбачений програмою НМТ. Пояснення представлено коротко та зрозуміло. Ви легко засвоїте мінімум теорії, достатній для розв'язання будь-яких завдань НМТ 
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <FaTasks />
                    </div>
                    <div className={styles.mainText}>
                        Задачі
                    </div>
                    <div className={styles.text}>
                        Ви навчитеся правильно формувати умову задачі, зображати коректні рисунки, які самі допомагатимуть знайти вам розв᾿язок. Ви зможете розуміти та розв᾿язувати задачі будь-якого рівня
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.number}>
                        <BsFillAwardFill />
                    </div>
                    <div className={styles.mainText}>
                        Високий бал НМТ
                    </div>
                    <div className={styles.text}>
                    Ви будете розвязувати домашні завдання у форматі НМТ. Крім того разом з вами ми розв'яжемо повний тест у форматі НМТ. Ви напишете НМТ з математики на 180+ балів та отримаєте бюджетне місце в омріяному ВНЗ
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Result;
