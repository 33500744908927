import React from 'react';
import styles from './Aboutme.module.scss';
import photo from '../../assets/images/photo.jpg';

const Aboutme = () => (
    <div className={styles.container} id="about">
        <h4 className={styles.title}>Про мене</h4>
        <div className={styles.info}>
            <div className={styles.photo}>
                <img className={styles.images_photo} src={photo} alt="study"/>
            </div>
            <div className={styles.about}>
                <div className={styles.name}>Олена Мельниченко</div> 
                <p>Викладач з математики, кандидат наук.</p>
                <p>
                    Автор ряду посібників з елементарної математики та підготовки до НМТ/ЗНО з математики.
                    Маю досвід роботи, як у звичайній школі, спеціалізованому ліцеї, так і в вищому навчальному закладі.
                    Педагог по духу, освіті та стилю життя.
                    Готую учнів до іспиту з <b>1995 року    </b>.
                </p>
                <p>
                    Маю великий досвід спілкування з учнями різного рівня підготовки.
                    Середній бал НМТ/ЗНО моїх учнів - <b>181</b>. Підготувала <b>182 двістібальника</b>.
                </p>
                <p>Дуже люблю математику і все, що повʼязано з нею.</p>
                <p>
                    Викладаю математику таким чином, щоб учні не тільки вивчили цей предмет і склали іспити, а й дійсно полюбили її і розуміли місце цієї складної науки в реальному житті.
                </p>
            </div>
        </div>
    </div>
);

export default Aboutme;
