export const popupAnimation = {
    initial: { y: 0, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.3 },
    exit: { y: 0, opacity: 0 }
};

export const phoneAnimation = {
    initial: { y: 100, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.3 },
    exit: { y: 100, opacity: 0 }
};