import React from 'react';
import Masonry from 'react-masonry-component';
import importAll from '../../utils/importAll';
import styles from './Reviews.module.scss';

const imagesLoadedOptions = { background: '.my-bg-image-el' };
const images = importAll(require.context('../../assets/images/reviews', false, /\.(png|jpe?g|svg)$/));

console.log('images', images);

const Reviews = () => (
  <div className={styles.wrapper} id="reviews">
    <div className={styles.content}>
      <h4 className={styles.title}>Відгуки</h4>
      <div className={styles.sliderContainer}>
        <Masonry
          className={styles.sliderContainer}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
          imagesLoadedOptions={imagesLoadedOptions}
        >
          {images.sort((a, b) => a.localeCompare(b, undefined, {numeric: true, sensitivity: 'base'})).map(item => (
            <div key={item} className={styles.item}>
              <img src={item} alt="review" />
            </div>
          ))}
        </Masonry> 
      </div>
    </div>
  </div>
);

export default Reviews;
