import React from 'react';
import SimpleHeader from '../../components/SimpleHeader';
import styles from './Politics.module.scss';

const Politics = () => (
  <>
    <SimpleHeader />
    <div className={styles.mainContent}>
      <h2>Політика конфіденційності. Угода про згоду на обробку персональних даних</h2>
      <b>Сторони:</b>
      <p>
        <b>Замовник</b> (будь-яка дієздатна фізична, фізична особа-підприємець, що замовила послуги у сфері підготовки до НМТ на сайті https://mathwithme.in.ua/) та Виконавець (фізична особа – підприємець Мельниченко Олена Петрівна, зареєстрований в Єдиному державному реєстрі юридичних осіб та фізичних осіб – підприємців в установленому чинним законодавством порядку та який є платником єдиного податку) приймають ці положення та погоджуються з ними:
      </p>
      <p>
        <b>Персональні дані</b> - будь-яка інформація про Замовника, у тому числі його ім'я, номер телефону та email, а також інша інформація, яку Замовник вказує на свій розсуд.
      </p>
      <p>
        Під <b>обробкою персональних даних</b> Замовника розуміються дії (операції) Виконавця з персональними даними, включаючи збір, систематизацію, накопичення, зберігання, уточнення (оновлення, зміну), використання, знищення персональних даних.
      </p>
      <p>
        <b>Виконавець інформує Замовника</b>, що всі персональні дані Замовника використовуються лише з метою підготовки та організації якісних послуг у сфері підготовки до НМТ.
      </p>
      <p>
        <b>Мета</b> надання Замовником персональних даних і подальшої обробки їх Виконавцем - отримання Замовником якісних послуг Виконавця у сфері підготовки до НМТ.
      </p>
      <p>
        <b>Виконавець зобов'язується</b> не надавати ніякої персональної інформації про Замовника іншим приватним особами і організаціям.
      </p>
      <p>
        <b>Замовник надає Виконавцю право</b> здійснювати наступні дії (операції) з персональними даними: збір та накопичення; безстрокове зберігання, уточнення (оновлення, зміну); використання; знищення; знеособлення.
      </p>
      <p>
        Відправка Замовником виконавцю інформації про себе в електронному вигляді є його <b>безумовною згодою на обробку його персональних даних.</b>
      </p>
    </div>
  </>
);

export default Politics;