import React from 'react';
import {
    FaVideo,
    FaTripadvisor,
    FaChalkboardTeacher,
    FaRegLightbulb,
    FaCameraRetro,
    FaThList,
    FaDna,
    FaDesktop,
    FaClipboardList
} from 'react-icons/fa';
import styles from './Study.module.scss';

const studiesArray = [
    { id: 1,icon: <FaVideo />, label: <span>Уроки наживо<br/>та відеоуроки</span> },
    { id: 2,icon: <FaTripadvisor />, label:  <span> Перевірка та аналіз<br/>домашнього завдання</span>},
    { id: 3,icon: <FaChalkboardTeacher />, label: <span>Робота в міні-групах</span> },
    { id: 4,icon: <FaRegLightbulb />, label: <span>Помісячна оплата</span> },
    { id: 5,icon: <FaCameraRetro />, label: <span>Робочі зошити</span> },
    { id: 6,icon: <FaThList />, label: <span>Спілкування в чаті</span> },
    { id: 7,icon: <FaDna />, label: <span>Формули, схеми, таблиці </span> },
    { id: 8,icon: <FaDesktop />, label: <span>Підсумкові тести<br/>та симуляції НМТ</span> },
    { id: 9,icon: <FaClipboardList />, label: <span>Психологічна підтримка<br/> та мотивування </span> }

]

const Study = () => (
    <div className={styles.container} id="education">
        <h4 className={styles.title}>Навчання на курсі</h4>
        <div className={styles.items}>
            {studiesArray.map(study => (
                 <div key={study.id} className={styles.studyItem}>
                    <div className={styles.icon}>{study.icon}</div>
                    {study.label}
                </div>
            ))}
        </div>
    </div>
);

export default Study;