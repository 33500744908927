import React, { Component } from 'react';
import bg from '../../assets/images/mainbg.svg';
import styles from './MainSection.module.scss';

class MainSection extends Component {
    state = {
        isScrolling: false
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        const { scrollY } = window;
        if (scrollY > 0) {
            this.setState({ isScrolling: true });
        } else {
            this.setState({ isScrolling: false });
        }
    }
    
    render () {
        const { isScrolling } = this.state;

        return (
            <div className={styles.mainSectionContainer}>
                <header className={[styles.header, isScrolling ? styles.isScrolling : 'default'].join(' ')}>
                    <div className={styles.container}>
                        <ul>
                            <li><a href="#about">Про мене</a></li>
                            <li><a href="#cons">Переваги</a></li>
                            <li><a href="#education">Навчання</a></li>
                            <li><a href="#courses">Курси</a></li>
                            <li><a href="#howitworks">Заняття</a></li>
                            <li><a href="#results">Результати</a></li>
                            <li><a href="#video">Відео</a></li>
                            <li><a href="#reviews">Відгуки</a></li>
                            <li><a href="#footer">Контакти</a></li>
                        </ul>
                    </div>
                </header>
                <div className={styles.mainInfo}>
                    <div>
                        <h1>Онлайн курси підготовки до НМТ  з математики</h1>
                        <h2>Авторський курс Олени Мельниченко</h2>
                        <a href="#courses">
                            Зареєструватися
                            <span />
                            <span />
                            <span />
                            <span />
                        </a>
                    </div>
                </div>
                <div className={styles.background}>
                    <img src={bg} alt="main" />
                </div>
            </div>
        );
    }
}

export default MainSection;
