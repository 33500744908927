import React from 'react';
import { TiArrowBackOutline } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import styles from './SimpleHeader.module.scss';

const SimpleHeader = () => (
  <div className={styles.container}>
    <Link to="/" className={styles.goBack}>
      <TiArrowBackOutline />
      <span className={styles.text}>Повернутися на головну</span>
    </Link>
  </div>
);

export default SimpleHeader;
