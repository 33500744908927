import React from 'react';
import styles from './HowItWorks.module.scss';

const HowItWorks = () => (
    <div className={styles.wrapprer} id="howitworks">
        <div className={styles.container}>
            <div className={styles.title}>
                Як проходять заняття на курсі
            </div>
            <div className={styles.text}>
                <p>
                    Після оплати ви отримаєте доступ до свого навчального кабінету та доступ до першого заняття
                </p>
                <p>
                    В понеділок відкривається нова тема. Ви можете переглянути два коротких уроки в записі в зручний для себе час, та пройти тести
                </p>
                <p>
                    Закріплення матеріалу відбувається в суботу щотижня наживо
                </p>
                <p>
                   Вивчення теми завершується проходженням підсумкового тесту
                </p>
                <p>
                   Симуляції НМТ дають змогу закріпити вивчений матеріал, та потренуватися складанню НМТ
                </p>
            </div>
        </div>
    </div>
);

export default HowItWorks;
