import React from 'react';
import { RiCloseCircleLine } from 'react-icons/ri';
import { FaTelegramPlane, FaViber } from 'react-icons/fa';
import { MdMail } from 'react-icons/md';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { popupAnimation } from '../../utils/animations';
import styles from './Modal.module.scss';

const Modal = ({ onClose, modalData }) => (
    <motion.div {...popupAnimation} className={styles.modal}>
        <div className={styles.container}>
            <div className={styles.text}>
                <div className={styles.title}>У вартість курсу входить:</div>
                <div className={styles.simple}>
                    {modalData.features.map((feature => (
                        <li key={feature}>{feature}</li>
                    )))}
                </div>
                <div className={styles.payments}>
                    <a
                        href={modalData.payLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Оплата онлайн
                    </a>
                    <a
                        href="https://drive.google.com/file/d/14xgs0MnuBSExKQHSh4DlD8mFwkUUln53/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Оплата за реквізитами
                    </a>
                </div>
                <div className={styles.red}>
                Після оплати відправте мені підтвердження платежу, прізвище та ім'я учня що буде навчатися, номер телефону та дійсну адресу електронної пошти, на яку я надішлю посилання, логін і пароль входу на курс!
                </div>
                <div className={styles.pay}>
                    <a
                        className={styles.telega}
                        href="https://t.me/melnichenko_ol"
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        <FaTelegramPlane /> Telegram
                    </a>
                    <a
                        className={styles.mail}
                        href="mailto:olena@mathwithme.in.ua"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            <MdMail /> Mail
                    </a>
                    <a
                        className={styles.viber}
                        href={typeof window.orientation !== 'undefined' ? "viber://chat?number=380973312427" : "viber://add?number=380973312427"}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            <FaViber /> Viber
                    </a>
                    <div className={styles.tiny}>
                        Реєструючись на курс ви погоджуєтесь з умовами <Link to="/dogovir" target="_blank">Договору публічної оферти</Link> та <Link to="/politics" target="_blank">Політикою конфіденційності</Link>
                    </div>
                </div>
            </div>
            <div className={styles.icon} onClick={onClose}>
                <RiCloseCircleLine />
            </div>
        </div> 
    </motion.div>
);

export default Modal;
