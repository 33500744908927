import React from 'react';
import { FaTelegram, FaYoutube, FaInstagram } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import tiktokIcon from '../../assets/images/tiktok.svg';
import banner from '../../assets/images/banner.jpg';
import styles from './Footer.module.scss';

const Footer = () => {
    return (
        <footer id="footer" className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.socialContainer}>
                    <div className={styles.mail}>
                        <div className={styles.text}>Зв'яжітся з нами:</div>
                        <div className={styles.contactItem}>
                            <MdEmail />
                            <a
                                href="mailto:olena@mathwithme.in.ua"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                olena@mathwithme.in.ua
                            </a>
                        </div>
                        <div className={styles.contactItem}>
                            <FaTelegram />
                            <a
                                href="https://t.me/olena_mela"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                t.me/olena_mela
                            </a>
                        </div>
                    </div>
                    <div className={styles.banner}>
                        <img src={banner} alt="banner" />
                    </div>
                    <div className={styles.networks}>
                        <a
                            href="https://www.tiktok.com/@mathwitme"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={tiktokIcon} alt="tiktok" />
                        </a>
                        <a
                            href="https://t.me/olena_mela"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaTelegram />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.youtube.com/channel/UC5sdLpSz_Xhc0Bi2vUkrLjw"
                        >
                            <FaYoutube />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/melnichenko_olena/"
                        >
                            <FaInstagram />
                        </a>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <div style={{ marginTop: 15 }}>
                        <Link to="/dogovir">Договір публічної оферти</Link> | <Link to="/politics">Політика конфіденційності</Link>
                    </div>
                    ФОП Мельниченко О.П.<br />
                    © Copyright all rights reserved. 
                </div>
            </div>
        </footer>
    );
}

export default Footer;
