export const INDIVIDUAL = 'Індивідуальний навчальний кабінет';
export const VIDEO = 'Відеоуроки наживо та в записі';
export const MATERIALS = 'Робочі зошити та всі необхідні матеріали';
export const TEST_TASKS = 'Тестові завдання до кожної теми та їх аналіз';
export const PREPARATION = 'Індивідуальний графік підготовки';
export const QUESTIONS = 'Можливість задати питання викладачу';
export const CHECK = 'Контроль процесу навчання';
export const HOME_WORK = 'Виконання домашнього завдання учнями';
export const REPEAT = 'Підсумкові тести та симуляції НМТ';
export const SUPPORT = 'Підтримка в телеграм-групі, індивідуально, та наживо під час онлайн-уроків';
export const AUTO_CONTROL = 'Автоматичний контроль процесу навчання';
export const VIDEO1 = 'Відеоаналіз тестів в записі';
export const SUPPORT1 = 'Підтримка в телеграм-групі';
export const VIDEO2 = 'Відеоуроки в записі';