import React from 'react';
import { Route, Switch, HashRouter } from 'react-router-dom';
import Home from '../../pages/Home';
import Dogovir from '../../pages/Dogovir';
import Politics from '../../pages/Politics';

const AppRouter = () => (
    <HashRouter>
        <Switch>
            <Route path="/politics">
                <Politics />
            </Route>
            <Route path="/dogovir">
                <Dogovir />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
    </HashRouter>
);

export default AppRouter;
