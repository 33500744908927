import React, { Component } from 'react';
import { FaPhone } from 'react-icons/fa';
import { GrFormClose } from 'react-icons/gr';
import { motion, AnimatePresence } from 'framer-motion';
import { phoneAnimation } from '../../utils/animations';
import styles from './CallMeBtn.module.scss';

class CallMeBtn extends Component {
    state = {
        showPhone: false,
        showForm: false,
        btnBottom: 35 
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        const { showForm } = this.state;
        if (window.scrollY > 150) {
            if (!showForm) this.setState({ showPhone: true });
        } else {
            this.setState({ showPhone: false });
        }

        const bodyHeight = document.getElementsByTagName('body')[0].offsetHeight;
        const footerHeight = document.getElementsByTagName('footer')[0].offsetHeight;
        if (window.scrollY + window.innerHeight + footerHeight >= bodyHeight) {
            this.setState({ btnBottom: footerHeight + 25 });
        } else {
            this.setState({ btnBottom: 35 });
        }
    }

    render() {
        const { showPhone, showForm, btnBottom } = this.state;

        return (
            <div className={styles.container}>
                <AnimatePresence>
                    {showPhone && (
                        <motion.div
                            {...phoneAnimation}
                            style={{ bottom: btnBottom }}
                            className={styles.circleButton}
                            onClick={() => this.setState({ showForm: true, showPhone: false })}
                        >
                            <FaPhone />
                        </motion.div>
                    )}
                </AnimatePresence>
                <AnimatePresence>
                    {showForm && (
                        <motion.div className={styles.form} {...phoneAnimation}>
                            <div className={styles.title}>
                                Є питання? Зателефонуйте мені!<br />
                                <a href="tel:+380973312427">+380973312427</a>
                            </div>
                            <div
                                className={styles.close}
                                onClick={() => this.setState({
                                    showForm: false,
                                    showPhone: window.scrollY > 150
                                })}
                            >
                                <GrFormClose />
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </div>
        );
    }
}

export default CallMeBtn;
