import React from 'react';
import ReactPlayer from 'react-player';
import styles from './Video.module.scss';

const Video = () => (
    <div className={styles.wrapper} id="video">
        <div className={styles.container}>
            <div className={styles.title}>
                Відео
            </div>
            <div className={styles.videoContainer}>
                <ReactPlayer width={0} height={0} url='' />
                <ReactPlayer width={0} height={0} url='' />
            </div>
        </div>
    </div>
);

export default Video;