import React, { Component } from 'react';
import Study from '../../components/Study';
import Advantages from '../../components/Advantages';
import Aboutme from '../../components/Aboutme';
import Courses from '../../components/Courses';
import Reviews from '../../components/Reviews';
import MainSection from '../../components/MainSection';
import HowItWorks from '../../components/HowItWorks';
import Result from '../../components/Results';
import Footer from '../../components/Footer';
import CallMeBtn from '../../components/CallMeBtn';
import Video from '../../components/Video';
import styles from './Home.module.scss';


class Home extends Component {
    render() {
        return(
            <div className={styles.home}>
                <>
                    <MainSection />
                    <Aboutme />
                    <Advantages />
                    <Study />
                    <Courses />
                    <HowItWorks />
                    <Result />
                    <Video />
                    <Reviews />
                    <Footer />
                    <CallMeBtn />
                </>
            </div>
        );
    }
}

export default Home;
